import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import Vant from 'vant';
import './assets/style/common.less';
import './assets/style/reset.less';
import 'vant/lib/index.css';
import store from './store/index';
import { Toast,Dialog } from 'vant';
//移动端设备插件
import 'amfe-flexible';

Vue.use(Toast,Dialog);
Vue.use(Vant);

Vue.filter('fMoney', function (money) {
  let num = money/100;
  return num.toFixed(2);
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
