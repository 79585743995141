<template>
  <div id="app">
    <!-- 渲染需要缓存的组件 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    <!-- 渲染不需要缓存的组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    this.$store.commit("set_phone");
    this.$store.commit("set_token");
  },
};
</script>

<style>
  body{
    font-size:16px !important;
  }
</style>
