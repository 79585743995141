import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
    mode: 'history',
    //进入详情页将滚动条设置到顶部
    scrollBehavior: () => ({
        y: 0
      }),
    routes: [
        {
            path: "/",
            redirect: "/product",
        },
        //购物车模块
        {
            path: "/cart",
            component: () => import("@/views/cart/index")
        },
        //测试模块
        {
            path: "/demo",
            component: () => import("@/views/demo/index"),
        },
        {
            path: "/demo/test",
            component: () => import("@/views/demo/test"),
        },
        {
            path: "/demo/son",
            component: () => import("@/views/demo/son"),
        },
        //商品分类
        {
            path: "/type",
            component: () => import("@/views/type/index")
        },
        //商品模块
        {
            path: "/product",
            component: () => import("@/views/product/index"),
            redirect: "/product/list",
            // 配置子路由
            children: [
                {
                    path: "list",
                    component: () => import("@/views/product/children/list"),
                    meta: {
                        keepAlive: true
                      },
                },
                {
                    path: "detail/:productId",
                    component: () => import("@/views/product/children/detail")
                }
            ]
        },
        //订单模块
        {
            path: "/order",
            component: () => import("@/views/order/index"),
            redirect:"/order/list",
            children: [
                {
                    path: "list",
                    component: () => import("@/views/order/children/list")
                },
                {
                    path: "detail",
                    component: () => import("@/views/order/children/detail")
                },
                {
                    path: "verify",
                    component: () => import("@/views/order/children/verify")
                },
                {
                    path: "pay",
                    component: () => import("@/views/order/children/pay")
                }
            ]
        },
        //我的模块
        {
            path: '/my',
            component: () => import('@/views/my/index.vue'),
            redirect: "/my/person",
            children: [
                {
                    path: 'person',
                    component: () => import('@/views/my/children/person.vue'),
                },
                {
                    path: 'set',
                    component: () => import('@/views/my/children/set.vue')
                }
            ]
        },
        //地址模块
        {
            path: '/address',
            component: () => import('@/views/address/index.vue'),
            redirect: "/address/list",
            children: [
                {
                    path: 'list',
                    component: () => import('@/views/address/children/list.vue'),
                },
                {
                    path: 'edit',
                    component: () => import('@/views/address/children/edit.vue')
                }
            ]
        },
        //账号模块
        {
            path: '/account',
            component: () => import('@/views/account/index.vue'),
            redirect: "/account/login",
            children: [
                {
                    path: 'login',
                    component: () => import('@/views/account/children/login.vue'),
                },
                {
                    path: 'register',
                    component: () => import('@/views/account/children/register.vue')
                }
            ]
        },
    ]
});