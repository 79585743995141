import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

//创建仓库
const store = new Vuex.Store({
    //定义状态
    state:{
        username:'张三1',
        age:100,
        phone:'',
        token:''
    },
    mutations:{
        set_username(state,payload){
            state.username = payload;
        },
        set_age(state,payload){
            state.age = payload;
        },
        set_phone(state,payload){
            if(!payload){
                state.phone = window.sessionStorage.getItem("tel");
            }else{
                state.phone = payload;
            }
            
        },
        set_token(state,payload){
            if(!payload){
                state.token = window.sessionStorage.getItem("token");
            }else{
                state.token = payload;
            }
        }
    }
  
});

export default store;